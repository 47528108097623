<template>
  <div>
    <div class="detail">
      <img src="@/assets/moblie/back.png" @click="back" class="back" />
      <p class="title">
        <span>{{ data[id].say }}</span>
      </p>
      <video
        :src="videoList[id]"
        poster="@/assets/moblie/poster.png"
        controls
      />
      <div class="video-text">
        <p>{{ data[id].text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      id: this.$route.query.id,
      videoList: [
        'https://www.lumibayedu.com/media/hongguanjingjixue.mp4',
        'https://www.lumibayedu.com/media/shijietongshi.mp4',
        'https://www.lumibayedu.com/media/zhongguoshehuishi.mp4',
        'https://www.lumibayedu.com/media/tongjixue.mp4',
        'https://www.lumibayedu.com/media/falvjingjixue.mp4'
      ],
      data: [
        {
          say: '洞悉宏观大势，摄取制度红利',
          text:
            '宏观经济学通识课系列旨在增进你对宏观经济概念和模型的理解，培养你预测宏观经济调控的能力。本门课程将介绍重要的宏观经济主题，包括增长的决定因素，商业周期，财政和货币政策，同时也将特别关注开放经济下的宏观问题诸如经常账户失衡、资本流动、汇率制度和金融危机。我们将时常以中国的宏观经济为背景，但也会充分借用美国、欧洲和新兴市场国家作为案例。相信这一系列跨国比较和国别案例分析能够帮助你建立起政策分析框架、拓展全球视野、更好地把握宏观大势。\n' +
            '本课程分为四个模块：第一个模块介绍宏观经济学基本概念和模型，介绍过程中将穿插一些历史事件帮助大家了解该学科发展背景以及理论对实际政策的影响；紧接着的两个模块讨论货币和财政政策，主要的着眼点还是在经济周期和经济波动；模块四转向经济增长，关注增长的可持续性、增长与开放的关系、以及新增长理论的实践意义。'
        },
        {
          say: '全球一盘棋，重塑世界观',
          text:
            '“世界通史系列”课程以孙隆基教授著作《新世界史》为蓝本，用通俗生动的语言为你诠释人类的共同过去与世界历史背后的故事。《新世界史》堪称首部由华人学者专为华语读者著述的全球通史，它将零散的世界史知识贯通成一幅完整的世界历史图像，是今天的读者重新认识世界的必备读物。对于世界史教学来说，本课程在提供准确的基础信息的同时，突破传统教科书的叙述方式，问题引导，纲举目张，摆脱机械式的死记硬背，让学习者能够更有效地掌握史实间的关联，以“全球一盘棋”的视野，打通世界历史的脉络。\n' +
            '本课程为孙隆基教授“世界通史系列”的第一部分，时间跨度从人类文明的起源到古代世界的终结。课程分为九个模块，从修正文明起源论开始，持续讲述最早的“文明古国”、印度欧罗巴人的来临、进入铁器时代的古代近东、波斯与希腊的历史纠葛、人类史上灿烂辉煌的“枢轴时代”、以及古代世界中大放异彩的罗马世界、弥勒与弥赛亚的神奇渊源、直到古代世界被“殡葬师”匈奴所终结。本课程以跨学科的方式，另辟蹊径，融会贯通，将人类历史的各个维度勾连起来，带你领略历史的深层逻辑，通晓人类的共同过去。'
        },
        {
          say: '听古今故事，知社会脉络',
          text:
            '中国社会史是一门帮助青年学生以及任何对中国历史和社会感兴趣的人们了解中国历史的通识课。本课程以微观和宏观相结合的方法，通过一个家庭的故事，逐步延伸到中国社会的各个方面，涉及到人口、衣食住行、农村和城市、秘密社会、风俗习惯、教育、社会组织、宗教信仰、法律与社会、日常生活、宗族与家庭等等。\n' +
            '本门课程通俗易懂，没有艰深的理论，但是有令人启发的视野；没有枯燥的说教，但是有生动的故事；没有宏大的国家叙事，但是有贴近底层的关怀；没有英雄的史观，但是有为民众写史的情怀。学习了这个课程，我们会对中国和中国人、中国历史和中国社会，有一个全面深刻的认识。'
        },
        {
          say: '揭秘人工智能时代的底层代码',
          text:
            '数字真有这么难？统计学与普通人距离太远？这一定是个天大的误解。\n' +
            '本门课程将带你轻松走进数字世界的统计学课程。课程从统计学是如何发展成为一门独立的学科出发，结合日常生活中的大小事，探讨统计学知识在自然、社会、经济、科学技术等各个领域的应用。本课通俗易懂、生动有趣，以培养学生形成统计学思维为目的，帮助大家更好地把握生活中的大数据。\n' +
            '本门课程为该通识课的系列一，介绍统计学发展的三个阶段，以科学家及生活中的故事为主线讲解统计学的发展历史及经典学术成果。课程首先讲述了18、19世纪古典统计学科是如何被天文、海航等人类活动的实际需求催生的，当时的数据记录方法是如何让统计学独立门户的；而后，描述了以通晓天文、物理，统计、数学的凯特勒为代表的科学家们，如何从现实案例中推开以描述性为主的近代统计学大门；最后，介绍了英国科学家皮尔森在以推断方法为主的现代统计学中做出了什么样的贡献。众所周知，统计学给社会的政治、经济、军事、医学、天文地理及人们的生活带来了巨大影响和变化。本门课程将带大家系统地学习统计学，以帮助大家更好地认识和理解世界。'
        },
        {
          say: '剖析虚伪与真相，探寻正义的成本',
          text:
            '本课程将循序渐进地探讨法律经济学，从经济学的视角，帮你搭建分析社会现象的框架和思维，并通过讨论法学中的权利、道德、因果关系和正义等重要问题，讲授信息、社会、政治和历史等与法律密切相关的知识，帮助你在领悟法理的同时，熟悉掌握经济学分析的功夫；让你建立“先了解社会，再了解法律”的方法论思维，扩充法律的学理基础，练好内功心法，获得独立解析现实法律问题、判断法治发展走向的能力。\n' +
            '本门课是法律经济学的入门课程。工欲善其事必先利其器，在这54讲里，熊秉元教授将深入浅出地讲授经济学分析框架的基础概念、思维起点和方法论立场。帮你找到一把万能的钥匙，窥探法律世界的精妙。'
        }
      ]
    }
  },
  watch: {},
  mounted () {
    window.addEventListener('beforeunload', (e) => this.beforeunloadHandler(e))
  },
  methods: {
    beforeunloadHandler (e) {
      localStorage.setItem('test1', 3)
    },
    back () {
      this.$router.go(-1)
    }
  },
  destroyed () {
    window.removeEventListener('beforeunload', (e) =>
      this.beforeunloadHandler(e)
    )
  }
}
</script>

<style scoped lang="less">
.back {
  width: 0.2rem;
  margin-bottom: 0.3rem;
}
.detail {
  display: flex;
  flex-flow: column;
  padding: 0.48rem;
  .title {
    display: flex;
    align-items: center;
    font-size: 0.36rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    img {
      margin-right: 0.3rem;
    }
  }
  video {
    padding: 0.22rem 0;
    height: 3.9rem;
    background-size: contain;
  }
  .video-text {
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .img-box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
    p {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      text-align: center;
      padding: 0.1rem;
    }
  }
  .safe-box {
    display: flex;
    flex-flow: column;

    div {
      display: flex;
      align-items: center;
      padding: 0.1rem;
    }
    img {
      width: 0.88rem;
      height: 0.88rem;
    }
    span {
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      padding-left: 0.2rem;
    }
  }
  .img-box2 {
    display: flex;
    flex-flow: column;
    div {
      display: flex;
      align-content: center;
      justify-content: space-around;
      padding-bottom: 0.2rem;
      img {
        width: 1.3rem;
        height: 1.3rem;
      }
      p {
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #706a45;
        line-height: 20px;
        padding-bottom: 0.2rem;
      }
    }
  }
  .code-box {
    display: flex;
    align-content: center;
    justify-content: center;
    div {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      img {
        width: 3rem;
        height: 3rem;
      }
      span {
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>
